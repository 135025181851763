@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  @page {
    size: auto;
    margin: 0mm;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}
