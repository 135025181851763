.toast{
  overflow: hidden;
}
.toast.active {
  animation-play-state: running;
  animation: slide-in 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
}
.toast.close {
  animation-play-state: running;
  animation: slide-out 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
}
@keyframes slide-in {
  from {
    transform: translateX(calc(100% + 30px));
  }
  to {
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
  }
}
@keyframes slide-out {
  from {
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
  }
  to {
    transform: translateX(calc(100% + 30px));
  }
}